import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogController, DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import $, { data } from 'jquery';
import slick from 'slick-carousel';
import * as moment from "moment";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {ConfirmationModal} from "../useralertpopup/noofdaysconfirmation";
import {ViewApplyTimeOffPopup} from "../viewapplytimeoffpopup/viewapplytimeoffpopup";
import {config} from "../../../shared/services/config";
import { ViewMyTimeoffSummaryComponent } from '../viewmytimeoffsummary/viewmytimeoffsummarycomponent';
import {SharedState} from "../../../shared/state/sharedstate";
import {Router} from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";
import { constants } from '../../../shared/services/constants';

@inject(DialogController,JwtService,LeavemanagementService,ValidationControllerFactory,DialogService,SharedState,Router,LoginService)
export class ApplyTimeOffComponent{
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) FromDate;
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) ToDate;

    FromDate = null;
    ToDate = null;
    leavetypes=[];
    locations = [];
    leavesummery = [];
    selected = {};
    showEmpDropdown = false;
    LeaveTypeId = '';
    selectedEmployees = [];
    EmployeeListIDs = [];
    LeaveType = "";
    showDropdown = false;
    selectedRadio = '1';
    years = [];
    holidays = [];
    selectedFrom = "full";
    selectedTo = "fullto";
    pageSize = 3;
    contactPageSize = 10;
    notifyemails = [];
    featureholidays = [];
    showExperience = false;
    isTableEmpty = true;
    showToDateRadios = true;
    Configurl=config.img_url;
    FromDateError = null;
    ToDateErrors = null;
    ErrorMessageShow=false;
    isFormSubmitted = false;
    sitemapId=constants.applytimeoffSiteMapID;

    constructor(controller,JwtService,LeavemanagementService,controllerFactory,dialogService,sharedState,router,loginservice){
        this.controller = controller;
        this.jwtService= JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.locid = this.jwtService.getLocationId();
        this.leavemanagementService = LeavemanagementService;
        this.selectedEmployees = [];
        this.FromDate = null;
        this.ToDate = null;
        this.dialogService = dialogService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.sharedState = sharedState;
        this.router = router;
        this.loginservice = loginservice;

        ValidationRules
            .ensure('LeaveType').required().withMessage('Time off type field is required.')
            .ensure('FromDate').required().withMessage('From field is required.')
            .ensure('ToDate').required().withMessage('To field is required.')
            .ensure('Reasonfortimeoff').required().withMessage('Reason for time off field is required.')
            .minLength(5)
            .withMessage('Reason for time off must have at least 5 characters.')
            .maxLength(200)
            .withMessage('Reason for time off cannot exceed 200 characters.')
            .ensure('totaldays').required().matches(/^\d+$/)
            .on(this);
    }
    validateEmails() {
        if (this.emails && this.emails.trim() !== '') {
            const emails = this.emails.split(',');
            const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
            const invalidEmails = [];
            for (const email of emails) {
                const trimmedEmail = email.trim();
                if (!emailRegex.test(trimmedEmail)) {
                    invalidEmails.push(trimmedEmail);
                }
            }
            if (invalidEmails.length > 0 || (emails.length > 1 && this.emails.indexOf(',') === -1)) {
                this.specifyemailerror = 'Invalid email address and Email(s) should be separated by commas';
                return false;
            }
        }
        this.specifyemailerror = '';
        return true;
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.mdi-calendar-clock');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.mdi-calendar-clock .sidebar-sub .apply-leave a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached(){
        this.getCurrentRouteURL();
        this.empid = this.jwtService.getEmployeeId();
        this.FromCalendar =  flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            maxDate: null,
            defaultDate: this.FromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
          onClose: (selectedDates, dateStr, instance) => {
              this.FromDate = dateStr;
              // Update the minDate of the "To" calendar
              this.ToCalendar.set('minDate', dateStr);
              // Set the selected date of the "To" calendar if it's earlier than the selected date in "From"
              if (this.ToDate && new Date(this.ToDate) < new Date(dateStr)) {
                  this.ToDate = dateStr;
                  this.ToCalendar.setDate(dateStr);
              }

            }
        });
        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });
        this.ToCalendar = flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: this.FromDate,
            maxDate: null,
            defaultDate: this.FromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,

            onClose: (selectedDates, dateStr, instance) => {
                this.ToDate = dateStr;
                },

            onReady: (selectedDates, dateStr, instance) => {
                if (!instance.input.value && this.FromDate) {
                    instance.setDate(this.FromDate);
                }
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });
        this.leavemanagementService.GetMyLeaveBalance(this.empid)
            .then(data => {
                this.leavesummery= data;
               // const filteredSummary = this.leavesummery.filter(item => item.LeaveTypeId === this.LeaveType);
                //const balanceLeave = filteredSummary.length > 0 ? filteredSummary[0].BalanceLeave : 0;

            });
        this.leavemanagementService.GetLeaveTypesForDropDown(this.empid)
            .then(data => {
                this.leavetypes= data;
            });
        this.leavemanagementService.LocationsForDropdown()
            .then(data => {
                this.locations= data;
            });
        this.leavemanagementService.YearsForDropdown()
            .then(data => {
                this.years= data;
            });
        const d = new Date();
        this.year = d.getFullYear();
        const selectedYear = this.year;
        const ID = this.locid;
        const timeoff = {
            LocationId:ID,
            Year:this.year,
            EmployeeID:this.empid,
        };
        this.leavemanagementService.GetEmployeeHolidayLists(timeoff)
            .then(data => {
                this.holidays = data;
                this.holidays.map((months) => {
                    const HolidayMonth = months.Month;
                });
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const currentMonthHolidays = this.holidays.filter(months => Number(months.Month) === currentMonth);
                const nextMonthsHolidays = this.holidays.filter(months => Number(months.Month) > currentMonth);
                const pastMonthsHolidays = this.holidays.filter(months => Number(months.Month) < currentMonth);
                pastMonthsHolidays.sort((a, b) => Number(a.Month) - Number(b.Month));
                this.displayedHolidays = currentMonthHolidays.concat(nextMonthsHolidays, pastMonthsHolidays);
            });
        sessionStorage.removeItem('activeTab');
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 3000);
        setTimeout(()=>{
            $('.leave-slick-list').slick({
                dots: false,
                infinite: false,
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 666,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding:'50px',
                        }
                    }
                ]
            })
        },5000);
        this.leavemanagementService.GetFeatureHoliday()
            .then(data => {
                this.featureholidays= data;
                
                this.featureholidays.map((days) =>{
                    const Months = days.Month;
                });
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1; // Add 1 because month index starts from 0
                const currentMonthFeatureHolidays = this.featureholidays.filter(days => Number(days.Month) === currentMonth);
                const nextMonthsFeatureHolidays = this.featureholidays.filter(days => Number(days.Month) > currentMonth);
                const pastMonthsFeatureHolidays = this.featureholidays.filter(days => Number(days.Month) < currentMonth);
                pastMonthsFeatureHolidays.sort((a, b) => Number(a.Month) - Number(b.Month));
                this.displayedFeatureHolidays = currentMonthFeatureHolidays.concat(nextMonthsFeatureHolidays, pastMonthsFeatureHolidays);
            });
        if (window.innerWidth >= 1280) {
                setTimeout(() => {
                    $('.holiday-slick-list').slick({
                        dots: false,
                        infinite: false,
                        arrows: true,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 1366,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 1280,
                                settings: {
                                    arrows: false,
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 666,
                                settings: {
                                    slidesToShow: 1,
                                    centerMode: true,
                                    centerPadding:'50px',
                                }
                            }
                        ]
                    });
                }, 5000);
            }
            this.addToRecentItems();
    }
    locationMatcher = (a, b) => {
        return a.key === b.key;
    };
    yearMatcher = (a, b) => {
        return a.Year === b.year;
    };
    monthFormat(Months){
        const inputDate = Months;
        const parsedDate = moment(inputDate, 'MM');
        const formattedDate = parsedDate.format('MMM');
        return formattedDate;
    }
    calculateNoOfDays(){
        if (this.FromDate === this.ToDate) {
            this.showToDateRadios = false;
        } else {
            this.showToDateRadios = true;
        }
        const selectedtype = this.LeaveType;
        const leave = this.leavesummery;
        leave.some((item) => {
            const summeryID = item.LeaveTypeID;
            const isEqual = summeryID == selectedtype;
            if (isEqual) {
                this.balanceLeave = item.BalanceLeave;
                return true;
            }
            return false;
        });
        if(this.selectedFrom === 'full'){
            this.FromFull = true;
        }else{
            this.FromFull = false;
        }
        if(this.selectedFrom === 'half'){
            this.FromHalf = true;
        }else{
            this.FromHalf = false;
        }
        if(this.selectedTo === 'fullto'){
            this.ToFull = true;
        }else{
            this.ToFull = false;
        }
        if(this.selectedTo === 'halfto'){
            this.ToHalf = true;
        }else{
            this.ToHalf = false;
        }
        if (this.ToDate) {
            this.showExperience = true;
        } else {
            this.showExperience = false;
        }
        let totaldays = {
            EmployeeID : this.empid,
            FromDateTime : this.FromDate,
            ToDateTime : this.ToDate,
            FromFullDayIND : this.FromFull,
            FromHalfDayIND : this.FromHalf,
            ToFullDayIND : this.ToFull,
            ToHalfDayIND : this.ToHalf,
            LeaveTypeId : this.LeaveType,
        };
        this.leavemanagementService.GetNoOfDays(totaldays)
            .then(data => {
                this.noofdays = data;
                if (data !== null) {
                    const result2 = data.match(/[a-zA-Z]+/g);
                    this.stringData = result2 ? result2.join(" ") : "";
                    const result = data.match(/[\d.]+/);
                    this.totaldays = result ? parseFloat(result[0]) : 0;
                }
                if(this.stringData.length > 0){
                    this.alertmessage = '';
                }
                const areAllBalanceLeavesZero = this.leavesummery.every(item => item.BalanceLeave === 0);
                if (this.totaldays > this.balanceLeave && !areAllBalanceLeavesZero) {
                    const message = 'You don\'t have sufficient leave balance in selected leave type. Do you want to auto adjust balance from other leave types ?';
                    this.dialogService.open({
                        viewModel: ConfirmationModal,
                        model: message
                    }).whenClosed(response => {
                        if (!response.wasCancelled) {
                            this.confirm = !response.wasCancelled;
                            this.leaveconfirm = this.confirm;
                        }
                    else {
                            this.notconfirmed = !response.wasCancelled;
                            this.leavenotconfirm = this.notconfirmed;
                            if (this.leavenotconfirm === false) {
                                this.alertmessage = "Applied time off(s) are more than balance time off(s). Do you want to proceed on loss of pay for remaining time off(s) ?";
                            }
                        }
                    });
                } else if (this.totaldays > this.balanceLeave && areAllBalanceLeavesZero) {
                    this.alertmessage = "Applied time off(s) are more than balance time off(s). Do you want to proceed on loss of pay for remaining time off(s) ?";
                }
            });
    }
    DisplayHolidays() {
        const d = new Date();
        this.year = d.getFullYear();
        this.locid = this.jwtService.getLocationId();
        let ID = this.locid;
        let selectedYear = this.year;
        if (this.Location) {
            ID = this.Location.LocationId;
        }
        if (this.Year) {
            selectedYear = this.Year.Year;
        }
        this.leavemanagementService.GetHolidayList(ID, selectedYear, this.empid)
            .then(data => {
                this.holidays = data;
                this.holidays.map((months) =>{
                    const HolidayMonth = months.Month;
                });
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const currentMonthHolidays = this.holidays.filter(months => Number(months.Month) === currentMonth);
                const nextMonthsHolidays = this.holidays.filter(months => Number(months.Month) > currentMonth);
                const pastMonthsHolidays = this.holidays.filter(months => Number(months.Month) < currentMonth);
                pastMonthsHolidays.sort((a, b) => Number(b.Month) - Number(a.Month));
                this.displayedHolidays = currentMonthHolidays.concat(nextMonthsHolidays, pastMonthsHolidays);
            });
    }
    HolidayMonthFormat(HolidayMonth){
        const inputDate = HolidayMonth;
        const parsedDate = moment(inputDate, 'MM');
        const formattedDate = parsedDate.format('MMM');
        return formattedDate;
    }
    async  toggleNotifyFields(checked) {
        this.showNotifyFields = checked;
        this. selectedRadio = '1';
    }
    onEmailSelected() {
        this.selectedRadio = '2';
        this.searchQuery = '';
        this.employenameerror = '';
        this.employenameerror = '';
    }
    async onEmployeeSelected() {
        this.selectedRadio = '1';
        this.emails = '';
        this.specifyemailerror = '';
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.leavemanagementService.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                this.empid = this.jwtService.getEmployeeId(); // Assuming this.empid is a string
                this.dropdownOptions = this.dropdownOptions.filter(option => option.EmployeeID !== parseInt(this.empid, 10));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "No records found"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
        let emails = this.emails || '';
        if (this.selectedRadio === '1') {
            if (!this.searchQuery || this.searchQuery.trim() === '') {
                this.employenameerror = "Employee name is required";
                return;
            }
            this.leavemanagementService.GetEmployeesForAdd(id).then(data => {
                this.Ids = data.EmployeeID;
                this.empmail = data.EmailID;
                this.EName = data.EmployeeName;
                let employeeemail = {
                    EmailIds: this.empmail,
                    PersonID: this.Ids,
                    EmployeeName: this.EName,
                };
                const existingEmployee = this.selectedEmployees.find(emp => emp.PersonID === id);
                if (existingEmployee) {
                    this.employenameerror = "Employee name already exists";
                    return;
                }
                this.EmployeeListIDs.push(employeeemail);
                this.selectedEmployees.push(employeeemail);
                this.searchQuery = '';
                this.employenameerror = '';
            });
        } else if (this.selectedRadio === '2') {
            if (this.validateEmails()) {
                const existingEmail = this.selectedEmployees.find(emp => emp.EmailIds === emails);
                if (existingEmail) {
                    this.specifyemailerror = "Email id already exists";
                    return;
                }
                if (emails.trim() !== '') {
                    const emailEntry = {
                        PersonID: -1,
                        EmailIds: emails
                    };
                    this.newmail = emailEntry;
                    this.notifyemails.push(this.newmail);
                    this.EmployeeListIDs.push(...this.notifyemails);
                    this.selectedEmployees.push(emailEntry);
                    this.emails = '';
                } else {
                    this.specifyemailerror = "Specify email is required";
                    return;
                }
                this.employenameerror = '';
                this.specifyemailerror = '';
                this.validatemessage = '';
            }
        }
        if (this.notifyind === true && this.EmployeeListIDs.length === 0 && this.notifyemails.length === 0) {
            this.validatemessage = "";
            return;
        }
    }
    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
            this.EmployeeListIDs.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);
        this.Ids = this.EmployeeListIDs;
    }
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
    save() {
        event.preventDefault();
        if (this.leaveconfirm === true) {
            this.userconfirmation = true;
        }
        if(this.leavenotconfirm === false) {
            this.userconfirmation = false;
        }
        const refreshEvent = new CustomEvent('refreshCTC');
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    if (this.stringData && this.stringData.length > 1) {
                        return;
                    }
                    if( this.notifyind === true && this.EmployeeListIDs.length === 0 && this.notifyemails.length === 0) {
                        this.validatemessage = "Atleast one peer is required";
                    } else {
                        this.validatemessage = "";
                        this.isFormSubmitted = true;
                        let applydata = {
                            EmployeeID: this.empid,
                            LoginEmployeeID: this.empid,
                            LocationID: this.locid,
                            LeaveTypeId: this.LeaveType,
                            FromDateTime:this.FromDate,
                            ToDateTime:this.ToDate,
                            NoofDays:this.totaldays,
                            ReasonforLeave:this.Reasonfortimeoff,
                            FromFullDayIND:this.FromFull,
                            FromHalfDayIND :this.FromHalf,
                            ToFullDayIND:this.ToFull,
                            ToHalfDayIND:this.ToHalf,
                            DocumentPath:this.file,
                            DocumentName:this.DocumentName,
                            NotifyEmployeeList:this.EmployeeListIDs,
                            NotifyPeersIND : this.notifyind,
                            MessageToPeers : this.comments,
                            LeaveApplicationID:this.LeaveApplicationID,
                            Userconfirmation : this.userconfirmation,
                        };
                        this.leavemanagementService.IsLeaveWorkflowApproverExist(this.locid).then(data => {
                            if (data == false) {
                                this.ErrorMessageShow = true;
                                this.isFormSubmitted = false;
                            }
                            else {
                                this.ErrorMessageShow = false;
                                this.isFormSubmitted = true;
                        this.leavemanagementService.SaveApplyLeave(applydata).then((response)=>{
                            this.leaveapplicationID = response;

                            this.openDialogBox();
                            if(this.FromCalendar){
                                this.FromCalendar.clear();
                            }
                            if(this.ToCalendar){
                                this.ToCalendar.clear();
                            }
                                 this.LeaveType = '';
                                 this.FromDate = '';
                                 this.ToDate = '';
                                 this.Reasonfortimeoff = '';
                                 this.FromDate = '';
                                 this.ToDate = '';
                                 this.totaldays = '';
                                 this.FromFull = '';
                                 this.half = '';
                                 this.ToFull = '';
                                 this.halfto = '';
                                 this.file = '';
                                 this.DocumentName = '';
                                 this.EmployeeListIDs = [];
                                 this.stringData = '';
                                 this.notifyind = '';
                                 this.comments = '';
                                 this.selectedEmployees = [];
                                 this.notifyemails = [];
                                 this.searchQuery = '';
                                 this.employenameerror = '';
                                 this.specifyemailerror = '';
                                 this.showNotifyFields = false;
                                 this.showToDateRadios = true;
                                 this.selectedFrom = "full";
                                 this.selectedTo = "fullto";
                                 this.alertmessage = "";
                            this.isFormSubmitted = false;
                        })
                    }
                });
                    }
                }
            });
    }
    openDialogBox() {
        this.dialogService.open({
            title: 'Saved Data',
            viewModel: ViewApplyTimeOffPopup,
            model: this.leaveapplicationID
        }).then(dialogResult => {
        });
    }
    showPopupGrid(LeaveTypeID) {
        this.dialogService.open({viewModel: ViewMyTimeoffSummaryComponent, model: LeaveTypeID});
    }
    clearDatePickers() {
        if (this.FromdatePicker) {
            this.FromdatePicker._flatpickr.clear();
        }
        if (this.TodatePicker) {
            this.TodatePicker._flatpickr.clear();
        }
    }
    resetForm() {
        if(this.FromCalendar){
            this.FromCalendar.clear();
        }
        if(this.ToCalendar){
            this.ToCalendar.clear();
        }
        this.alertmessage = "";
        this.LeaveType = '';
        this.FromDate = '';
        this.ToDate = '';
        this.Reasonfortimeoff = '';
        this.FromDate = '';
        this.totaldays = '';
        this.FromFull = '';
        this.half = '';
        this.ToFull = '';
        this.file = '';
        this.DocumentName = '';
        this.EmployeeListIDs = [];
        this.stringData = '';
        this.notifyind = '';
        this.comments = '';
        this.selectedEmployees = [];
        this.notifyemails = [];
        this.searchQuery = '';
        this.employenameerror = '';
        this.specifyemailerror = '';
        this.LeaveTypeError = [];
        this.ReasonfortimeoffErrors = [];
        this.showNotifyFields = false;
        this.showToDateRadios = true;
        this.FromDateError = [];
        this.ToDateErrors = [];
        this.selectedFrom = "full";
        this.selectedTo = "fullto";
    }
    timeoffClick() {
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('timeoff-open');
    }
    holidayClick() {
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('holiday-open');
        $('.holiday-slick-list').slick({
                dots: false,
                infinite: false,
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            arrows: false,
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 666,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding:'50px',
                        }
                    }
                ]
            });
    }

    addToRecentItems(){
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {

         });
     }
}
