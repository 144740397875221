import { DialogController } from 'aurelia-dialog';
export class Confirmcancelmodel {
  static inject = [DialogController,Element];
  isDragging = false;
    dialog;

    constructor(controller) {
      this.controller = controller;
      document.addEventListener('keydown', this.handleKeyPress);
  }
  activate(data) {
      this.AllDays=data;
  }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.controller.cancel();
        }
    };
  cancel() {
     this.controller.cancel();
  }
  ok() {
     this.controller.ok();
  }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    detached() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}
