import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
//import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {config} from "../../../shared/services/config";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { constants } from '../../../shared/services/constants';
import { Confirmcancelmodel } from '../cancelmodel/confirmcancelmodel';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,LeavemanagementService,ValidationControllerFactory,DialogService,LoginService,Router)

export class ApproveTimeOffComponent {
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;
    pageSize = config.grid_PazeSize;
    topicsPageSize = config.grid_PazeSize;
    NotePageSize = config.grid_PazeSize;
    OnlineTopic = [];
    InputContainer = null;
    selectedValue = "true";
    validationMessage=false;
    reasonErrorMessage=false;
    showReasonError=false;
    Reason = '';
    savecancel=false;
    DisplayOnBehalfOf='';
    CategoryId=constants.leaveworkflowcategoryid;
    isFormSubmitted = false;
    constructor(controller, ProfileService,JwtService,LeavemanagementService,ValidationControllerFactory,DialogService, config,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.config = config;
        this.loginservice = loginservice;
        this.router = router;
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.leavemanagementService.GetLeaveDaywiseSummary(this.LeaveApplicationID).then(data => {
            this.days = data;
        });
        ValidationRules
            .ensure('selectedStatus').displayName("select action").required()
            .ensure('selectedClarifier').displayName("select Clarifier").required()
            .ensure('Comments').required().withMessage('Comments are required.')
            .on(this);
    }
    statusMatcher = (a) => {
        return a === this.StatusCode;
    };
    activate(approveData){
        this.data = approveData;
        this.photopath = this.data.PhotoPath;
        this.LeaveApplicationID = this.data.selectedRecordId;
        this.EmployeeId = this.data.EmployeeId;
        this.StatusCode = this.data.StatusCode;
        this.Actions = this.data.actions;
        this.Actions.map(item => {
            if(item.ActionID === this.ActionID){
                this.HeaderName = item.ActionName;
            }
        });
    }
    selectedCancleStatus(cancleStatus){
        this.selectedStatus = cancleStatus.ActionID;
    }
    selecteClarifier(clarifierdata){
        this.SenderId = clarifierdata.SenderId
    }
    selecteClarify(selecteClarify){
        this.SenderId = selecteClarify.SenderId
    }
    async bind() {
        setTimeout(()=>{
            Scrollbar.init(document.querySelector('.viewtimeoff-pop-scrollbar'));
        },1000);
        this.userPhoto =config.img_url + this.photopath;
    }
    async attached(){
        this.specializationInput.focus();

        document.addEventListener('click', this.handleDocumentClick);
        this.leavemanagementService.GetAdditionalComments(this.LeaveApplicationID,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.DocPath = data.DocumentPath;
            });
        this.leavemanagementService.clarificationDropdown(this.LeaveApplicationID).then(data => {
            this.clarificationData = data;
        });
        this.leavemanagementService.clarifyDropdown(this.LeaveApplicationID).then(data => {
            this.clarifly = data;
            this.clariflyid=data.SenderId;
        });
        this.leavemanagementService.GetLeaveApplicationDetails(this.LeaveApplicationID).then(data=>
        {
            this.timeoffRequest=data;
            this.finalFromDate=this.timeoffRequest.From;
            this.finalToDate=this.timeoffRequest.To;
            const passing={
                EmployeeId:this.EmployeeId,
                Fromdate: this.finalFromDate,
                Todate: this.finalToDate,
                LeaveApplicationID: this.LeaveApplicationID,
                LoggedInEmployeeId:this.empid
            };
            this.leavemanagementService.GetLeaveDetailsofOtherEmployees(passing).then(data=>
            {
                this.OtherEmployees  =data;
            });
        });
        this.leavemanagementService.GetLeaveDaywiseSummary(this.LeaveApplicationID).then(data=>
        {
            this.Days  =data;
            const containerCheckbox = document.createElement('div');
            const labelCheckbox = document.createElement('label');
            labelCheckbox.textContent = "Select the day:   "
            containerCheckbox.appendChild(labelCheckbox);
            const checkboxOptions = this.Days;
            data.forEach((option) => {
                const checkboxLabel = document.createElement('label');
                const checkboxInput = document.createElement('input');
                checkboxInput.type = 'checkbox';
                checkboxInput.name = 'checkboxOptions';
                checkboxInput.value = option.LeaveApplicationDayWiseSummaryID;
                checkboxInput.id = this.Days.Date;
                checkboxLabel.textContent = option.Day;
                checkboxLabel.appendChild(checkboxInput);
                containerCheckbox.appendChild(checkboxLabel);
            });
                if (this.InputContainer) {
                    this.InputContainer.appendChild(containerCheckbox);
                }
            });
            const buttonContainer = document.createElement('div');
            buttonContainer.className = 'button-container';
            if (this.InputContainer) {
                this.InputContainer.appendChild(buttonContainer);
            }
            this.leavemanagementService.GetPastLeavesData(this.EmployeeId,this.LeaveApplicationID,).then(data=>
        {
            this.PastLeaves =data;
        });
        this.leavemanagementService.GetEmployeePeersLeaveNotifyEmails(this.LeaveApplicationID).then(data=>
        {
            this.PeersLeaveNotifyEmails=data;
        });
        this.leavemanagementService.DisplayOnBehalfOf(6,this.LeaveApplicationID,this.empid).then(data=>
            {
                this.DisplayOnBehalfOf=data;
                console.log(this.DisplayOnBehalfOf);
            });

    }
    updateSelectedValue(event) {
        this.selectedValue = event.target.value;
        this.validationMessage=false;
    }
    RefreshProfileImage(data){
        this.userPhotoElement = document.querySelector('.rounded-full.dropdown.inline-block');
        this.userPhotoElement.src = data;
    }

    nameLength(row) {
        return row.Type.length;
    }
    cancel(){
        this.controller.cancel();
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024; // Maximum file size in bytes (2.5 MB)
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }
    submit(){
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const data = {
                        LoginEmployeeID: this.empid,
                        EmployeeID: this.EmployeeId,
                        LeaveApplicationID: this.LeaveApplicationID,
                        Status: this.selectedStatus ? this.selectedStatus : this.StatusCode,
                        Comments: this.Comment,
                        //CommentANR:this.Common,
                        CommentANR: this.Reason,
                        DocumentName: this.DocumentName,
                        DocumentPath: this.file,
                        SenderId: this.SenderId ? this.SenderId : null,
                        ClarifyId: this.clariflyid
                    };
                    const CancelList = [];
                    console.log(data);
                    this.Reason = this.timeoffRequest.Reason;
                    console.log(this.Reason);
                    if (this.Reason == null || this.Reason == "" || this.Reason == undefined) {
                        this.reasonErrorMessage = true;
                        const errorElement = document.querySelector(".messages");
                        if (errorElement) {
                            errorElement.scrollIntoView();
                        }
                    } else {
                        this.reasonErrorMessage=false;
                        if (data.Status === 'INA' && this.selectedValue == 'true' ) {
                            const result = this.dialogService.open({
                                viewModel: Confirmcancelmodel,
                                model: this.Days.length,
                            }).whenClosed(response => {
                                if (response.wasCancelled) {
                                    return;
                                }
                                else {
                                    this.reasonErrorMessage=false;
                                    const displayRadiosArray = {
                                        CancelList: CancelList,
                                        EmployeeID: this.EmployeeId,
                                        LoginEmployeeID: this.empid,
                                        LeaveApplicationID: this.LeaveApplicationID,
                                        NOofdays: this.NOofdays,
                                        Comments: this.Comments,
                                        DocumentName: this.DocumentName,
                                        DocumentPath: this.file,
                                        SingleDayIND: this.SingleDayIND,
                                        Status: this.selectedStatus ? this.selectedStatus : this.StatusCode,
                                        AllDayIND: this.selectedValue
                                    };
                                    if(this.savecancel==false)
                                    {
                                        this.savecancel=true;
                                        this.leavemanagementService.CancelTimeoffRequest(displayRadiosArray).then(() => {
                                        this.controller.ok(true);
                                      
                                    });
                                    }
                                   
                                }
                            })
                        }
                        else if (data.Status === 'INA' && this.selectedValue == 'false') {
                            const fieldValues = {};
                            const checkboxElements = document.querySelectorAll(`input[name="checkboxOptions"]:checked`);
                            if (checkboxElements.length === 0) {
                                this.validationMessage = true;
                            } else {
                                this.validationMessage = false;
                                const result = this.dialogService.open({
                                    viewModel: Confirmcancelmodel,
                                    model:this.selectedValue,
                                }).whenClosed(response => {
                                    if (response.wasCancelled) {
                                        return;
                                    }
                                    else {
                                        fieldValues.Option = checkboxElements ? Array.from(checkboxElements).map(checkbox => checkbox.value) : [];
                                        fieldValues.Option.forEach(response => {
                                            fieldValues.EmployeeID = this.EmployeeId;
                                            fieldValues.Value = response.Option;
                                            CancelList.push(fieldValues);
                                            const displayRadiosArray = {
                                                CancelList: CancelList,
                                                EmployeeID: this.EmployeeId,
                                                LoginEmployeeID: this.empid,
                                                LeaveApplicationID: this.LeaveApplicationID,
                                                NOofdays: this.NOofdays,
                                                Comments: this.Comments,
                                                DocumentName: this.DocumentName,
                                                DocumentPath: this.file,
                                                SingleDayIND: this.SingleDayIND,
                                                AllDayIND: this.selectedValue,
                                                Status: this.selectedStatus ? this.selectedStatus : this.StatusCode,
                                            };
                                            if(this.savecancel==false)
                                            {
                                                this.savecancel=true;
                                            this.leavemanagementService.CancelTimeoffRequest(displayRadiosArray).then(() => {
                                                this.controller.ok(true);

                                            });
                                        }
                                        });
                                    }
                                })
                            }
                        }
                        else if (data.Status === 'CLR') {
                            this.leavemanagementService.clarifyDropdown(data).then(() => {
                            });
                            this.isFormSubmitted = true;
                            this.leavemanagementService.ApproveTimeOffRequests(data).then(() => {
                                this.controller.ok(true);

                            });
                        }
                        else {
                            this.isFormSubmitted = true;
                            this.leavemanagementService.ApproveTimeOffRequests(data).then(() => {
                                this.controller.ok(true);
                            });
                            
                            //this.window.location.reload();
                            
                        }
                       // this.router.navigateToRoute('employeetimeoffdetails');
                        //window.location.reload();
                        this.leavemanagementService.GetLeaveApplicationDetails(this.LeaveApplicationID).then(data=>
                            {
                                this.timeoffRequest=data;
                                console.log(data);
                               
                            });
                    }
                }});
    }
    otherleavesClick(){
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('open-content');
    }
    pastleaveClick(){
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('open-content');
    }
    peersClick(){
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('open-content');
    }
    additionalClick(){
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('open-content');
    }
    getSelectedCheckboxOptions(fieldName){
        const checkboxElements = this.InputContainer.querySelectorAll(`input[name='${fieldName.replace(' ', '')}']:checked`);
        const selectedOptions = Array.from(checkboxElements).map(element => element.value);
        return selectedOptions;
    }
    getDownloadLink(DocumentPath){
        const basePath =  config.img_url+ DocumentPath;
        link.href=basePath;
        return basePath;        
    }
    downloadFile(DocumentPath){
        const downloadfile = config.img_url +DocumentPath;
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentPath;
            link.target = '_blank';
            link.click();
    }
    detached(){
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    detached(){
        document.removeEventListener('click', this.handleDocumentClick);
    }
    handleTextareaBlur(){
        if (!this.Reason.trim()) {
            this.reasonErrorMessage = true;
        } else {
            this.reasonErrorMessage = false;
        }
    }
    handleDocumentClick = (event) => {
        if (event.target == this.specializationInput) {
            this.reasonErrorMessage  = false; // Hide the error message when clicking outside the textarea
        }
    };
   
}
