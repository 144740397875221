import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { DialogService } from 'aurelia-dialog';
import { LeavemanagementService } from '../../shared/services/leavemanagementservice';
import { App } from '../../app';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";


@inject(SharedState,LeavemanagementService, Router, HttpClient, ValidationControllerFactory,DialogService,App,LoginService,JwtService,Element)
export class InstantApproval {
    
    routerView = "main-content";
    InstantApprovaldetails=[];
    guid = '';
    userId = '';
        
    constructor(sharedState,LeavemanagementService, router, httpClient, controllerFactory,dialogService,App,loginservice,JwtService,element) {
        
        this.sharedState = sharedState;
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.dialogService = dialogService;
        this.controller = controllerFactory.createForCurrentScope();
        this.app = App;
        this.jwtService = JwtService;
        this.loginservice = loginservice;

      }

    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
      this.app.updatePropertyValue(false);
      document.querySelector('router-view').classList.remove(this.routerView);

      const url_string = window.location.href;
      var url = new URL(url_string);
       // const url = new URL("http://183.82.120.94:5050/instantapproval?guid=3bacb92d-0c20-476b-8701-195f0d2dd10b&UserId=507");

        this.guid = url.searchParams.get('guid');
        this.userId = url.searchParams.get('UserId');
        console.log(this.guid);
        console.log(this.userId);

        if (this.guid && this.userId) {
            this.leavemanagementService
                .GetInstantApprovalDetails(this.guid, this.userId)
                .then(InstantApprovaldetails => {
                    this.InstantApprovaldetails = InstantApprovaldetails;
                    console.log(this.InstantApprovaldetails);
                })
                .catch(error => {
                    console.error('Error fetching InstantApprovaldetails:', error);
                    // Handle the error as needed (e.g., show an error message to the user)
                });
        }
    }
  }
