import {inject,bindingMode,bindable } from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
import flatpickr from "flatpickr";
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {JwtService} from "../../../shared/services/jwtservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {ConfirmationModal} from "../../leavemanagement/useralertpopup/noofdaysconfirmation";
import {config} from "../../../shared/services/config";
import {HeaderService} from "../../../shared/services/headerservice";
import { Router } from 'aurelia-router';
import {Viewtimeoffrequestcomponent} from "../../leavemanagement/viewtimeoffrequest/viewtimeoffrequestcomponent";
import {LoginService} from "../../../shared/services/loginservice";



@inject(DialogController,JwtService,LeavemanagementService,HeaderService,ValidationControllerFactory,DialogService,Router,LoginService)
export class AssignTimeOffComponent {
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) FromDate;
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) ToDate;
    FromDate = null;
    ToDate = null;
    selectedFrom = "full";
    selectedTo = "fullto";
    selectedEmployees = [];
    EmployeeListIDs = [];
    showEmpDropdown = false;
    showDropdown = false;
    selectedRadio = '1';
    showToDateRadios = true;
    notifyemails = [];
    topicsPageSize = config. grid_PazeSize;
    pageSize=config.grid_PazeSize;
    showExperience = false;

    constructor(controller,JwtService,LeavemanagementService,headerservice,controllerFactory,dialogService,router,loginservice) {

        this.controller = controller;
        this.jwtService= JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.selectedEmployees = [];
        this.FromDate = null;
        this.ToDate = null;
        this.headerservice = headerservice;
        this.dialogService = dialogService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.router = router;
        this.loginservice = loginservice;


        ValidationRules
            .ensure('Department').required().withMessage('The department field is required.')
            .ensure('selectedName').required().withMessage('The employee name field is required.')
            .ensure('LeaveType').required().withMessage('The time off type field is required.')
            .ensure('FromDate').required().withMessage('From date field is required.')
            .ensure('ToDate').required().withMessage('To date field is required.')
            .ensure('Reasonfortimeoff').required().withMessage('Reason for time off  field is required.')
            .minLength(5)
            .withMessage('Reason for time off must have at least 5 characters.')
            .maxLength(200)
            .withMessage('Reason for time off cannot exceed 200 characters.')
            .ensure('totaldays').required().matches(/^\d+$/)
            .on(this);
    }
    validateEmails() {
        if (this.emails && this.emails.trim() !== '') {
            const emails = this.emails.split(',');
            const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
            const invalidEmails = [];
            for (const email of emails) {
                const trimmedEmail = email.trim();
                if (!emailRegex.test(trimmedEmail)) {
                    invalidEmails.push(trimmedEmail);
                }
            }
            if (invalidEmails.length > 0 || (emails.length > 1 && this.emails.indexOf(',') === -1)) {
                this.specifyemailerror = 'Invalid email address and Email(s) should be separated by commas';
                return false;
            }
        }
        this.specifyemailerror = '';
        return true;
    }
    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        //
        // }
        this.empid = this.jwtService.getEmployeeId();
        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: 'today',
            maxDate: null,
            defaultDate: this.FromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.FromDate = dateStr;
            }
        });

        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });

        flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: 'today',
            maxDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.ToDate = dateStr;
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });

        this.headerservice.dynamicHeader(this.empid)
            .then(data => {
                this.departmentId = data.DepartmentId;
                this.statusVal = 'ACT';
                this.leavemanagementService.GetEmployeesDepartmentDropdown(this.empid,this.statusVal)
            .then(data => {
                this.departments= data;
            });
            });
        this.leavemanagementService.dynamicHeaderLogo(this.empid)
            .then(data => {
                this.employeedetails = data;
                this.dynamicLogo=config.img_url + data.LoginLogo;
                this.userPhoto=config.api_img_url + data.PhotoPath;
                this.designation = data.Designation;
            });
    }
    // cancel() {
    //     this.router.navigate('leavemanagementadmin');
    // }
    selectemployee(data){
         this.statusVal = 'ACT';
         this.updateddepartmentid = data;
         this.leavemanagementService.GetEmployeesNameDropdown(this.empid,this.statusVal,this.updateddepartmentid)
            .then(data =>{
           this.employeeropdowns = data;
            });
    }
    selecteleavetype(data){
        const selectedname = data;
        this.EmpName=data;
        console.log(data);
        this.leavemanagementService.GetLeaveTypeDropdown(selectedname).then(data =>{
            this.assignleavetypes = data;
        });
    }
    handleSelectChange() {
        this.balanceleave(this.LeaveType);
        this.calculateNoOfDays();
    }
    balanceleave(data){
        this.typeid = data;
        this.leavemanagementService.GetMyLeaveBalance(this.empid)
            .then(data => {
                this.leavesummery= data;
                const selectedLeaveTypes = this.leavesummery.filter(item => item.LeaveTypeID == this.LeaveType);
                if (selectedLeaveTypes.length > 0) {
                    const selectedLeaveType = selectedLeaveTypes[0];
                    this.balanceLeave = selectedLeaveType.BalanceLeave;
                }
            });
        this.leaveapplictionID = 0;
        this.leavemanagementService.GetPastLeavesData(this.EmpName,this.leaveapplictionID).then(data=>{
            this.pastleaves = data;
        })
    }
    calculateNoOfDays(){
         this.showExperience = true;
         if (this.FromDate === this.ToDate) {
            this.showToDateRadios = false;
        } else {
            this.showToDateRadios = true;
        }
        const selectedtype = this.LeaveType;
         const leave = this.leavesummery;
         leave.some((item) => {
            const summeryID = item.LeaveTypeID;
            const isEqual = summeryID == selectedtype;
            if (isEqual) {
                this.balanceLeave = item.BalanceLeave;
                return true;
            }
            return false;
        });
         if(this.selectedFrom === 'full'){
            this.FromFull = true;
        }else{
            this.FromFull = false;
        }
        if(this.selectedFrom === 'half'){
            this.FromHalf = true;
        }else{
            this.FromHalf = false;
        }
        if(this.selectedTo === 'fullto'){
            this.ToFull = true;
        }else{
            this.ToFull = false;
        }
        if(this.selectedTo === 'halfto'){
            this.ToHalf = true;
        }else{
            this.ToHalf = false;
        }
        if (this.ToDate) {
            this.showExperience = true;
        } else {
            this.showExperience = false;
        }
        let totaldays = {
            EmployeeID : this.empid,
            FromDateTime : this.FromDate,
            ToDateTime : this.ToDate,
            FromFullDayIND : this.FromFull,
            FromHalfDayIND : this.FromHalf,
            ToFullDayIND : this.ToFull,
            ToHalfDayIND : this.ToHalf,
            LeaveTypeId : this.LeaveType,
        };
        this.leavemanagementService.GetNoOfDays(totaldays)
            .then(data => {
                this.noofdays = data;
                if (data !== null) {
                    const result2 = data.match(/[a-zA-Z]+/g);
                    this.stringData = result2 ? result2.join(" ") : "";
                    const result = data.match(/[\d.]+/);
                    this.totaldays = result ? parseFloat(result[0]) : 0;
                }
                if (this.totaldays > this.balanceLeave) {
                    const message = 'you don\'t have sufficient leave balance in selected leave type. Do you want to auto adjust balance from other leave types?';
                    this.dialogService.open({
                        viewModel: ConfirmationModal,
                        model: message
                    }).whenClosed(response => {
                        if (!response.wasCancelled) {
                            this.confirm = !response.wasCancelled;
                            this.leaveconfirm = this.confirm;
                        } else {
                            this.notconfirmed = !response.wasCancelled;
                            this.leavenotconfirm = this.notconfirmed;
                        }
                    });
                }
            });
        const From = this.FromDate;
        const TO = this.ToDate;
        this.applicationid = 0;
       // this.EmpName = this.Name;
        let otherleavedata = {
            EmployeeId : this.EmpName,
            DepartmentID:this.departmentId,
            LeaveApplicationID : this.applicationid,
            Fromdate : this.FromDate,
            Todate : this.ToDate,
            LoggedInEmployeeId:this.empid
        };
        this.leavemanagementService.GetOtherLeavedetails(otherleavedata).then(data=>{
            this.otherleaves = data;
            console.log( this.otherleaves);
        });
    }
    async  toggleNotifyFields(checked) {
        this.showNotifyFields = checked;
    }
    onEmailSelected() {
        this.selectedRadio = '2';
    }
    async onEmployeeSelected() {
        this.selectedRadio = '1';
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.leavemanagementService.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                this.empid = this.jwtService.getEmployeeId();
                this.dropdownOptions = this.dropdownOptions.filter(option => option.EmployeeID !== parseInt(this.empid, 10));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "No results found"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
        let emails = this.emails || '';
        if (this.selectedRadio === '1') {
            if (!this.searchQuery || this.searchQuery.trim() === '') {
                this.employenameerror = "Employee name is required";
                return;
            }
            this.leavemanagementService.GetEmployeesForAdd(id).then(data => {
                this.Ids = data.EmployeeID;
                this.empmail = data.EmailID;
                this.EName = data.EmployeeName;
                let employeeemail = {
                    EmailIds: this.empmail,
                    PersonID: this.Ids,
                    EmployeeName: this.EName,
                };
                const existingEmployee = this.selectedEmployees.find(emp => emp.PersonID === id);
                if (existingEmployee) {
                    this.employenameerror = "Employee name already exists";
                    return;
                }
                this.EmployeeListIDs.push(employeeemail);
                this.selectedEmployees.push(employeeemail);
                this.searchQuery = '';
                this.employenameerror = '';
            });
        } else if (this.selectedRadio === '2') {
            if (this.validateEmails()) {
                const existingEmail = this.selectedEmployees.find(emp => emp.EmailIds === emails);
                if (existingEmail) {
                    this.specifyemailerror = "Email id already exists";
                    return;
                }
                if (emails.trim() !== '') {
                    const emailEntry = {
                        PersonID: -1,
                        EmailIds: emails
                    };
                    this.newmail = emailEntry;
                    this.notifyemails.push(this.newmail);
                    this.EmployeeListIDs.push(...this.notifyemails);
                    this.selectedEmployees.push(emailEntry);
                    this.emails = '';
                } else {
                    this.specifyemailerror = "Specify email is required";
                    return;
                }
                this.employenameerror = '';
                this.specifyemailerror = '';
                this.validatemessage = '';
            }
        }
    }
    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
            this.EmployeeListIDs.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);
        this.Ids = this.EmployeeListIDs;
    }
    handleFileSelect(files) {
       // const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };

        // if (file && file.size > maxSizeInBytes) {
        //     this.uploadErrors = [{ error: { message: 'File size exceeds 2MB limit.' } }];
        //     // Optionally, you can reset the file input to clear the selected file
        //     // this.upload = null;
        // } else {
        //     // Clear any previous error messages
        //     this.uploadErrors = [];
        // }
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    save() {
        event.preventDefault();
        if (this.leaveconfirm === true) {
            this.userconfirmation = true;
        }
        if(this.leavenotconfirm === false) {
            this.userconfirmation = false;
        }
        const refreshEvent = new CustomEvent('refreshCTC');
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    if (this.stringData && this.stringData.length > 1) {
                        return;
                    }
                    if( this.notifyind === true && this.EmployeeListIDs.length === 0 && this.notifyemails.length === 0) {
                        this.validatemessage = "Atleast one peer is required";
                    }
                    else {
                        this.validatemessage = "";
                        let data = {
                            EmployeeID: this.selectedName,
                            LeaveTypeId: this.LeaveType,
                            DepartmentID: this.Department,
                            FromDateTime:this.FromDate,
                            ToDateTime:this.ToDate,
                            NoofDays:this.totaldays,
                            ReasonforLeave:this.Reasonfortimeoff,
                            FromFullDayIND:this.FromFull,
                            FromHalfDayIND :this.FromHalf,
                            ToFullDayIND:this.ToFull,
                            ToHalfDayIND:this.ToHalf,
                            DocumentPath:this.file,
                            DocumentName:this.DocumentName,
                            NotifyEmployeeList:this.EmployeeListIDs,
                            NotifyPeersIND : this.notifyind,
                            MessageToPeers : this.comments,
                            LeaveApplicationID:this.LeaveApplicationID,
                            Userconfirmation : this.userconfirmation,
                            LoginEmployeeID: this.empid,
                        };
                        console.log(data);
                        this.leavemanagementService.SaveAssignLeave(data).then((response)=>{
                            this.assignleaveID = response;
                            this.openDialogBox();
                            this.selectedName = '';
                            this.Department = '';
                            this.LeaveType = '';
                            this.FromDate = '';
                            this.ToDate = '';
                            this.Reasonfortimeoff = '';
                            this.FromDate = '';
                            this.ToDate = '';
                            this.totaldays = '';
                            this.FromFull = '';
                            this.half = '';
                            this.ToFull = '';
                            this.halfto = '';
                            this.file = '';
                            this.DocumentName = '';
                            this.EmployeeListIDs = [];
                            this.stringData = '';
                            this.notifyind = '';
                            this.comments = '';
                            this.selectedEmployees = [];
                            this.notifyemails = [];
                            this.searchQuery = '';
                            this.balanceLeave = '';
                        })
                    }
                }
            });
    }
    openDialogBox() {
        this.dialogService.open({
            title: 'Saved Data',
            viewModel: Viewtimeoffrequestcomponent,
            model: {
                viewdata: this.assignleaveID,
            },
        }).then(dialogResult => {
        });
    }

    cancel(){
        this.router.navigateToRoute('employeetimeoffdetails');
    }
}